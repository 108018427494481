@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Yuji+Boku&display=swap');


.container {
  width: 80%;
  margin: 5px auto;

}

nav {
  display: flex;
  align-items:  center;
  width:100%;
  padding-top: 10px;
  margin: 0 auto;
  justify-content: space-between;
  color: white;

}

nav ul {
  list-style: none;
  padding: 0px;
  margin: 10px 0px;
}

nav ul li a {
  text-decoration: none;
  color: white;
}

nav ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

nav ul:first-of-type li:first-of-type{
  font-weight: bold;
  font-size: 1.1rem;
}

/* ###### HEADER ###### */

header {
  position: relative;
  font-family: Lato;
  margin-left: 30px;
  color: white;
}

header div {
  padding-top: 150px;

}

header div h1 {
  font-size:75px;
}

header div p {
  width: 550px;
  font-size: 28px;
  line-height: 28px;
}

header img {
  position:absolute;
  z-index: -1;
  width:1630px;
  right: -50%;
  top: -20px;
}

header div a {
  padding: 20px;
  display: block;
  margin-top: 70px;
  width: 170px;
  border-radius: 40px;
  color: white;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}


header div a, .newsletter input[type="submit"] {
  border: 1px solid rgb(49, 99, 95);
  color:rgb(255, 254, 254);
  background: rgb(2, 36, 109);
}

header div a, .newsletter input[type="submit"]:hover {
  
  color:rgb(255, 254, 254);
  background: rgb(53, 94, 182);
}

main {
  padding-top: 100px;
}

h2 {
  color: white;
  font-size: 45px;
  margin-bottom: 100px;
  text-align: center;
  width: 100%;
}

h3 {
  font-size:30px;
  color: rgb(196, 196, 197);
}

.services {
  margin-left: 30px;
  margin-top: 300px;
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0px 180px 0px;
  position: relative;
}

.service-card {
  width: 480px;
  height: 400px;
  background: #222;
  display: inline-block;
  border: 4px solid rgb(207, 207, 207);
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0pc 0px 53px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 52px -19px rgba(0, 0, 0, 0.75);

 /* transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards; */
}
/* @keyframes slide-in {
  100% { transform: translateX(0%); } 
} */

.service-description{
  width: 48%;
}

.newsletter {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  
  margin-bottom: 280px;
  color: white;
}

.newsletter * {
  padding: 20px;
  border-radius: 40px;
  border: none;
  margin: 0px;
  background: none;
  font-size: 20px;
}


.newsletter input[type="email"] {
  width: 60%;
  display: inline-block;
  border: 1px solid rgb(31, 18, 214);
  margin-right: 20px;
  background-color: rgb(238, 238, 238);
}

.newsletter input[type="submit"] {
  width: 170px;
  color: rgb(53, 53, 138);
  cursor: pointer;
  color: aliceblue;
}

.thanks {
  font-size: 30px;
  width: 70%;
  margin: 120px auto;
  text-align: center;
  
}

@media screen and (max-width: 1600px) {
  header img {
    width: 1230px;
    right: -50%;
    top: -28px
  }
}

@media screen and (max-width: 1380px) {
  header img {
    right: -60px;
    top: -20px;
  }

  header div {
    padding-top: 100px;
  }

  header div h1 {
    font-size: 55px
  }

  header div p {
    width: 400px;
  }

}

.service-one, .service-two, .service-three, .service-four {
  background-size: 120% !important;

}

.service-one {
  background: url("../images/lib1.jpg")center;
}

.service-two {
  background: url("../images/lib2.jpg")center;
}

.contact input[type="submit"] {
  width: 40%;
}

@media screen and (max-width: 1150px) {
  header img {
    width: 900px;
    right: -50%;
    top: 320px;
  }

  header div h1 {
    font-size: 55px;
  }

  header div p {
    width: 400px
  }

  .service-container {
    display: block;
  }

  .service-card {
    width: 100%;
    height: 400px;
  }

  .service-description {
    background-size: 120% !important;
  }

  .contact input[type="submit"] {
    width: 40%;
  }
}

@media screen and (max-width: 950px) {
  header img {
    width: 730px;
    right: -50%;
    top: 520px;
  }

  .newsletter input[type="email"] {
    width: 100%;
    margin-right: 0px;
  }

  .newsletter input[type="submit"] {
    margin-top: 40px;
  }

 /* header div h1 {
    font-size: 55px;
  }

  header div p {
    width: 400px
  }

  .service-container {
    display: block;
  }

  .service-card {
    width: 100%;
    height: 400px;
  }

  .service-description {
    background-size: 120% !important;
  } */
  
  .contact {
    width: 90%;
  }

  .contact input[type="submit"] {
    width: 100%;
    margin: 40px 0px;
  }
}