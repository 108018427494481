@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Yuji+Boku&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Yuji+Boku&display=swap);
html{ 
  background: linear-gradient(300deg,#1a4974,#0b3965,#1b63a9);
  background-size: 180% 180%;
  animation: gradient-animation 15s ease infinite;  
  color: white;
}

body {
  margin:0;
  padding: 0px;
  font-family: Lato;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing:greyscale;
  overflow-x: hidden;
  /*padding-bottom: 320px;*/
  /*backgroung-image: url("./Group_3.svg");
  /*background-position: bottom;
  background-repeat: no-repeat;*/
  color: white;
}

body *{
  transition: all 500ms ease-in-out;
}

.container {
  width: 80%;
  margin: 0 auto;

}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.center{
    max-width: 960px;
    margin: 0 auto;
    padding: 0 2%;
    display: flex;
    flex-wrap: wrap;
}

.main{
    padding: 40px;
    width: 100%;
    /*background-color: rgb(52, 97, 192);*/
    min-height: 400px;
}

.menu{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menu h3{
    color: white;
}

.menu a{
    color: white;
    text-decoration: none;
    border:1px solid white;
    padding: 3px 8px;
    border-radius: 5px;
}

.form{
    display:flex;
    max-width: 600px;
    width: 100%;
    margin: 50px auto;
    flex-wrap: wrap;
    justify-content: center;
}

.items-form{
    margin:0 auto;
    max-width: 540px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
}

.items-form input[type=text]{
    width:48%;
    height:36px;
    margin:8px 0px;
    border: 0;
    padding: 8px 8px;
    border-radius: 4px;;
}

.form h2{
    color: rgb(204, 222, 224);
    font-size: 16px;
    text-align: center;
    
}

.items-form button{
    width:100%;
    height: 40px;
    background-color: rgb(20, 116, 160);
    color: aliceblue;
    font-size: 18px;
    
    cursor: pointer;
    border: 0;
    border-radius: 4px;
}

.selector {   
    height:36px;
    margin:8px auto;
    color: rgb(15, 2, 2);
    width:100%;
    border: 1px;
    padding: 1px 1px;
    
}

@media screen and(max-width: 768px) {
    .items-form input[type=text]{
        width:100%;
    }
}

.mySelect__value-container{
    height: 36px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    width:100%;
    border: 1px;
    padding: 1px 1px;
   }
/* ########### FOOTER ############
.col {
    padding: 1rem;
    background-color: #33b5e5;
    border: 2px solid #fff;
    color: #fff;
    text-align: left;

    flex: 1 0 auto;

}
*/
body {
    font-family: "Open Sans", sans-serif;
  }
  
/* ############ LINKS ############## */

a { color: rgb(179, 180, 180); }
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.nobullet {
    list-style-type: none;
 }


/*
.body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
  
  main {
    flex: 1 0 auto;
  }
  */

/* Subscribe me on Youtube
https://bit.ly/3m9avif
*/

/*==================== 
  Footer 
====================== */

/* Main Footer */
footer .main-footer{  padding: 2px 0;  background: #141425; align: center;
}
footer ul{  padding-left: 2px;  list-style: none;}

/* Copy Right Footer */
.footer-copyright { background: rgb(18, 18, 26); padding: 5px 0;}
.footer-copyright .logo {    display: inherit;}
.footer-copyright nav {    float: right;    margin-top: 5px;}
.footer-copyright nav ul {  list-style: none; margin: 0;  padding: 0;}
.footer-copyright nav ul li { border-left: 1px solid #505050; display: inline-block;  line-height: 12px;  margin: 0;  padding: 0 8px;}
.footer-copyright nav ul li a{  color: #969696;}
.footer-copyright nav ul li:first-child { border: medium none;  padding-left: 0;}
.footer-copyright p { color: #969696; margin: 2px 0 0;}

/* Footer Top */
.footer-top{  background: #252525;  padding-bottom: 30px; margin-bottom: 30px;  border-bottom: 3px solid #222;}

/* Footer transparent */
footer.transparent .footer-top, footer.transparent .main-footer{  background: transparent;}
footer.transparent .footer-copyright{ background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3) ;}

/* Footer light */
footer.light .footer-top{ background: #f9f9f9;}
footer.light .main-footer{  background: #f9f9f9;}
footer.light .footer-copyright{ background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3) ;}

/* Footer 4 */
.footer- .logo {    display: inline-block;}

/*==================== 
  Widgets 
====================== */
.widget{  padding: 20px;  margin-bottom: 30px;}
.widget.widget-last{  margin-bottom: 0px;}
.widget.no-box{ padding: 20px; background-color: transparent;  margin-bottom: 40px;
  box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none; -ms-box-shadow: none; -o-box-shadow: none;}
.widget.subscribe p{  margin-bottom: 18px;}
.widget li a{ color: #7e8e9c;}
.widget li a:hover{ color: #4b92dc;}
.widget-title {margin-bottom: 20px;}
.widget-title span {background: #839FAD none repeat scroll 0 0;display: block; height: 1px;margin-top: 25px;position: relative;width: 20%;}
.widget-title span::after {background: inherit;content: "";height: inherit;    position: absolute;top: -4px;width: 50%;}
.widget-title.text-center span,.widget-title.text-center span::after {margin-left: auto;margin-right:auto;left: 0;right: 0;}
.widget .badge{ float: right; background: #7f7f7f;}

.typo-light h1, 
.typo-light h2, 
.typo-light h3, 
.typo-light h4, 
.typo-light h5, 
.typo-light h6,
.typo-light p,
.typo-light div,
.typo-light span,
.typo-light small{  color: #fff;}

ul.social-footer2 { margin: 0;padding: 0; width: auto;}
ul.social-footer2 li {display: inline-block;padding: 0;}
ul.social-footer2 li a:hover {background-color:#35748d;border-radius: 10px;}
ul.social-footer2 li a {display: block; height:30px;width: 30px;text-align: center;}
.btn{background-color: #4f1eff; color:#fff;border-radius: 5px; padding: 2px 20px; margin: 40px;}
.btn:hover, .btn:focus, .btn.active {background: #4b92dc;color: #fff;
-ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
-o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
transition: all 250ms ease-in-out 0s;

}
.row {
    
    flex-direction: row;
    margin: 2px auto;
    padding: 2px auto;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    
}

.container {
  width: 80%;
  margin: 5px auto;

}

nav {
  display: flex;
  align-items:  center;
  width:100%;
  padding-top: 10px;
  margin: 0 auto;
  justify-content: space-between;
  color: white;

}

nav ul {
  list-style: none;
  padding: 0px;
  margin: 10px 0px;
}

nav ul li a {
  text-decoration: none;
  color: white;
}

nav ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

nav ul:first-of-type li:first-of-type{
  font-weight: bold;
  font-size: 1.1rem;
}

/* ###### HEADER ###### */

header {
  position: relative;
  font-family: Lato;
  margin-left: 30px;
  color: white;
}

header div {
  padding-top: 150px;

}

header div h1 {
  font-size:75px;
}

header div p {
  width: 550px;
  font-size: 28px;
  line-height: 28px;
}

header img {
  position:absolute;
  z-index: -1;
  width:1630px;
  right: -50%;
  top: -20px;
}

header div a {
  padding: 20px;
  display: block;
  margin-top: 70px;
  width: 170px;
  border-radius: 40px;
  color: white;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}


header div a, .newsletter input[type="submit"] {
  border: 1px solid rgb(49, 99, 95);
  color:rgb(255, 254, 254);
  background: rgb(2, 36, 109);
}

header div a, .newsletter input[type="submit"]:hover {
  
  color:rgb(255, 254, 254);
  background: rgb(53, 94, 182);
}

main {
  padding-top: 100px;
}

h2 {
  color: white;
  font-size: 45px;
  margin-bottom: 100px;
  text-align: center;
  width: 100%;
}

h3 {
  font-size:30px;
  color: rgb(196, 196, 197);
}

.services {
  margin-left: 30px;
  margin-top: 300px;
}

.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0px 180px 0px;
  position: relative;
}

.service-card {
  width: 480px;
  height: 400px;
  background: #222;
  display: inline-block;
  border: 4px solid rgb(207, 207, 207);
  border-radius: 10px;
  box-shadow: 0px 0px 52px -19px rgba(0, 0, 0, 0.75);

 /* transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards; */
}
/* @keyframes slide-in {
  100% { transform: translateX(0%); } 
} */

.service-description{
  width: 48%;
}

.newsletter {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  
  margin-bottom: 280px;
  color: white;
}

.newsletter * {
  padding: 20px;
  border-radius: 40px;
  border: none;
  margin: 0px;
  background: none;
  font-size: 20px;
}


.newsletter input[type="email"] {
  width: 60%;
  display: inline-block;
  border: 1px solid rgb(31, 18, 214);
  margin-right: 20px;
  background-color: rgb(238, 238, 238);
}

.newsletter input[type="submit"] {
  width: 170px;
  color: rgb(53, 53, 138);
  cursor: pointer;
  color: aliceblue;
}

.thanks {
  font-size: 30px;
  width: 70%;
  margin: 120px auto;
  text-align: center;
  
}

@media screen and (max-width: 1600px) {
  header img {
    width: 1230px;
    right: -50%;
    top: -28px
  }
}

@media screen and (max-width: 1380px) {
  header img {
    right: -60px;
    top: -20px;
  }

  header div {
    padding-top: 100px;
  }

  header div h1 {
    font-size: 55px
  }

  header div p {
    width: 400px;
  }

}

.service-one, .service-two, .service-three, .service-four {
  background-size: 120% !important;

}

.service-one {
  background: url(/static/media/lib1.172d51cb.jpg)center;
}

.service-two {
  background: url(/static/media/lib2.42794974.jpg)center;
}

.contact input[type="submit"] {
  width: 40%;
}

@media screen and (max-width: 1150px) {
  header img {
    width: 900px;
    right: -50%;
    top: 320px;
  }

  header div h1 {
    font-size: 55px;
  }

  header div p {
    width: 400px
  }

  .service-container {
    display: block;
  }

  .service-card {
    width: 100%;
    height: 400px;
  }

  .service-description {
    background-size: 120% !important;
  }

  .contact input[type="submit"] {
    width: 40%;
  }
}

@media screen and (max-width: 950px) {
  header img {
    width: 730px;
    right: -50%;
    top: 520px;
  }

  .newsletter input[type="email"] {
    width: 100%;
    margin-right: 0px;
  }

  .newsletter input[type="submit"] {
    margin-top: 40px;
  }

 /* header div h1 {
    font-size: 55px;
  }

  header div p {
    width: 400px
  }

  .service-container {
    display: block;
  }

  .service-card {
    width: 100%;
    height: 400px;
  }

  .service-description {
    background-size: 120% !important;
  } */
  
  .contact {
    width: 90%;
  }

  .contact input[type="submit"] {
    width: 100%;
    margin: 40px 0px;
  }
}
